<template>
  <div class="pbf">
     
     <BrandTabHarvests
      :inurl="'/products/' + props.id + '/harvests'"      
      />

  </div>
</template>

<script setup>
 
const {$api, $tagsUtil, $ga} = useNuxtApp()

const props = defineProps({
  id: {
    type: Number
  }
})

</script>



<style scoped>
 

</style>
